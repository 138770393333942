import React, { useEffect, useState } from 'react'
import { EtapaProps } from '../types'
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { validateEtapa2Data } from '../validations'

const Etapa2View: React.FC<
  EtapaProps & {
    etapa2Data: {
      humidadeMedia: string
      temperaturaAmbienteMedia: string
      temperaturaMediaModulos: string
      velocidadeMediaVento: string
      coberturaDasNuvens: string
      nTotalModulos: string
      totalMdwcInspecionado: string
      irradianciaMedia: string
      temperaturaRefletida: string
      emissividade: string
      informacoesAdicionais: string
      recomendacoes: string
    }
    edit: boolean
    status?: number
    saveInspection: (redirectTo?: string) => void
    onFieldChange: (fieldName: string, value: string) => void
  }
> = ({ handleBack, handleNext, etapa2Data, onFieldChange, edit, saveInspection, status }) => {
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target
    onFieldChange(name, value)
  }
  const { t } = useTranslation()

  const [errors, setErrors] = useState<{ [key: string]: string }>({})
  const [nextDisabled, setNextDisabled] = useState(false)
  const handleValidatioAndSave = async (redirectTo?: string) => {
    const result = await validateEtapa2Data(etapa2Data, t)
    if (Object.keys(result).length === 0) {
      saveInspection(redirectTo)
    } else {
      setErrors(result)
    }
  }

  useEffect(() => {
    if (status === 4) {
      setNextDisabled(true)
    }
  }, [status])

  return (
    <Stack
      sx={{
        margin: '24px 10%',
        display: 'flex',
        flexDirection: 'column',
        gap: '30px',
        backgroundColor: 'white',
        padding: '20px',
        borderRadius: '16px',
        border: '1px solid #C4C4C4',
      }}
    >
      <Stack direction="column" gap={2}>
        <Typography variant="h6" fontWeight="700">
          {t('Condições Climáticas Médias')}
        </Typography>
        <Stack direction="row" gap={2} sx={{ width: '100%' }}>
          <TextField
            type="text"
            value={etapa2Data.humidadeMedia ?? ''}
            variant="outlined"
            name="humidadeMedia"
            onChange={handleInputChange}
            size="small"
            sx={{ width: '33%' }}
            id="input-humidadeMedia"
            label={t('Umidade Média')}
            error={Boolean(errors['humidadeMedia'])}
            helperText={errors['humidadeMedia'] || ''}
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
          />

          <TextField
            type="text"
            name="temperaturaAmbienteMedia"
            value={etapa2Data.temperaturaAmbienteMedia}
            onChange={handleInputChange}
            variant="outlined"
            size="small"
            sx={{ width: '33%' }}
            id="input-temperaturaAmbienteMedia"
            label={t('Temperatura Ambiente Média')}
            error={Boolean(errors['temperaturaAmbienteMedia'])}
            helperText={errors['temperaturaAmbienteMedia'] || ''}
            InputProps={{
              endAdornment: <InputAdornment position="end">°C</InputAdornment>,
            }}
          />

          <TextField
            type="text"
            name="temperaturaMediaModulos"
            value={etapa2Data.temperaturaMediaModulos}
            onChange={handleInputChange}
            variant="outlined"
            size="small"
            sx={{ width: '33%' }}
            id="input-temperaturaMediaModulos"
            label={t('Temperatura Média dos Módulos')}
            error={Boolean(errors['temperaturaMediaModulos'])}
            helperText={errors['temperaturaMediaModulos'] || ''}
            InputProps={{
              endAdornment: <InputAdornment position="end">°C</InputAdornment>,
            }}
          />
        </Stack>
        <Stack direction="row" gap={2} sx={{ width: '100%' }}>
          <TextField
            type="text"
            name="velocidadeMediaVento"
            value={etapa2Data.velocidadeMediaVento}
            onChange={handleInputChange}
            variant="outlined"
            size="small"
            sx={{ width: '33%' }}
            id="input-velocidadeMediaVento"
            label={t('Velocidade Média do Vento')}
            error={Boolean(errors['velocidadeMediaVento'])}
            helperText={errors['velocidadeMediaVento'] || ''}
            InputProps={{
              endAdornment: <InputAdornment position="end">m/s</InputAdornment>,
            }}
          />
          <TextField
            type="text"
            name="irradianciaMedia"
            value={etapa2Data.irradianciaMedia}
            onChange={handleInputChange}
            variant="outlined"
            size="small"
            sx={{ width: '33%' }}
            id="input-irradianciaMedia"
            label={t('Irradiância Média')}
            error={Boolean(errors['irradianciaMedia'])}
            helperText={errors['irradianciaMedia'] || ''}
            InputProps={{
              endAdornment: <InputAdornment position="end">W/m2</InputAdornment>,
            }}
          />
          <FormControl sx={{ width: '33%' }} size="small" error={Boolean(errors['coberturaDasNuvens'])}>
            <InputLabel
              id="input-coberturaDasNuvens"
              style={{ backgroundColor: 'white', padding: '0 7.5px' }}
            >
              {t('Cobertura das Nuvens')}
            </InputLabel>
            <Select
              labelId="input-coberturaDasNuvens"
              name="coberturaDasNuvens"
              value={etapa2Data.coberturaDasNuvens}
              onChange={(e) => onFieldChange(e.target.name, e.target.value)}
            >
              {['0', '10', '20', '30', '40', '50', '60', '70', '80', '90'].map((i) => (
                <MenuItem key={i} value={i}>
                  {i}%
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>{errors['coberturaDasNuvens'] || ''}</FormHelperText>
          </FormControl>
        </Stack>
      </Stack>
      <Stack direction="column" gap={2}>
        <Typography variant="h6" fontWeight="700">
          {t('Parâmetros')}
        </Typography>
        <Stack direction="row" gap={2} sx={{ width: '100%' }}>
          <TextField
            type="number"
            name="nTotalModulos"
            value={etapa2Data.nTotalModulos}
            onChange={handleInputChange}
            variant="outlined"
            size="small"
            required
            error={Boolean(errors['nTotalModulos'])}
            helperText={errors['nTotalModulos'] || ''}
            sx={{ width: '50%' }}
            id="input-nTotalModulos"
            label={t('Número Total de Módulos')}
            InputProps={{
              inputProps: {
                min: '0',
                step: '1',
              },
            }}
            onKeyDown={(e) => {
              if (e.key === '.' || e.key === ',') {
                e.preventDefault()
              }
            }}
          />
          <TextField
            type="number"
            name="totalMdwcInspecionado"
            value={etapa2Data.totalMdwcInspecionado}
            onChange={handleInputChange}
            variant="outlined"
            size="small"
            sx={{ width: '50%' }}
            id="input-totalMdwcInspecionado"
            label={t('Total MWDC a ser Inspecionado')}
            required
            error={Boolean(errors['totalMdwcInspecionado'])}
            helperText={errors['totalMdwcInspecionado'] || ''}
          />
        </Stack>

        <Stack direction="row" gap={2} sx={{ width: '100%' }}>
          <TextField
            type="text"
            name="temperaturaRefletida"
            value={etapa2Data.temperaturaRefletida}
            onChange={handleInputChange}
            variant="outlined"
            size="small"
            sx={{ width: '50%' }}
            id="input-temperaturaRefletida"
            label={t('Temperatura Refletida')}
            error={Boolean(errors['temperaturaRefletida'])}
            helperText={errors['temperaturaRefletida'] || ''}
            InputProps={{
              endAdornment: <InputAdornment position="end">°C</InputAdornment>,
            }}
          />
          <TextField
            type="text"
            name="emissividade"
            value={etapa2Data.emissividade}
            onChange={handleInputChange}
            variant="outlined"
            size="small"
            sx={{ width: '50%' }}
            id="input-emissividade"
            label={t('Emissividade')}
            error={Boolean(errors['emissividade'])}
            helperText={errors['emissividade'] || ''}
            InputProps={{
              endAdornment: <InputAdornment position="end">ε</InputAdornment>,
            }}
          />
        </Stack>
      </Stack>
      <Stack direction="column" gap={2}>
        <Typography variant="h6" fontWeight="700">
          {t('Informações Adicionais')}
        </Typography>
        <Stack direction="row" gap={2} sx={{ width: '100%' }}>
          <TextField
            type="text"
            name="informacoesAdicionais"
            value={etapa2Data.informacoesAdicionais}
            onChange={handleInputChange}
            variant="outlined"
            size="small"
            sx={{ width: '100%' }}
            id="input-informacoesAdicionais"
            label={t('Informações Adicionais')}
            multiline
            rows={4}
            error={Boolean(errors['informacoesAdicionais'])}
            helperText={errors['informacoesAdicionais'] || ''}
          />
        </Stack>
        {edit && (
          <Stack direction="row" gap={2} sx={{ width: '100%' }}>
            <TextField
              type="text"
              name="recomendacoes"
              value={etapa2Data.recomendacoes}
              onChange={handleInputChange}
              variant="outlined"
              size="small"
              sx={{ width: '100%' }}
              id="input-recomendacoes"
              label={t('Recomendações para o relatório')}
              multiline
              rows={4}
              error={Boolean(errors['recomendacoes'])}
              helperText={errors['recomendacoes'] || ''}
            />
          </Stack>
        )}
      </Stack>

      <Stack
        width={'100%'}
        gap={2}
        py={2}
        flexDirection="row"
        alignItems="flex-end"
        justifyContent="space-between"
      >
        <Button variant="outlined" onClick={handleBack}>
          {t('Voltar')}
        </Button>

        <Box display="flex" gap={2}>
          <Button
            variant="contained"
            onClick={() => handleValidatioAndSave('/sites/inspections')}
            sx={{
              backgroundColor: '#0C4A7D',
              color: 'white',
              '&:hover': {
                backgroundColor: '#084a5d',
              },
            }}
          >
            {t('Salvar e Sair')}
          </Button>
          <Button
            variant="contained"
            onClick={() => handleValidatioAndSave()}
            disabled={nextDisabled}
            sx={{
              backgroundColor: '#0C4A7D',
              color: 'white',
              '&:hover': {
                backgroundColor: '#084a5d',
              },
            }}
          >
            {t('Salvar e Avançar')}
          </Button>
        </Box>
      </Stack>
    </Stack>
  )
}

export default Etapa2View
